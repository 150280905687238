import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import htmx from 'htmx.org'


function formAndPools() {
    const form = document.getElementById('bookingForm');

    if (!form) return;

    const pool = form.querySelector('#pool');
    const date = form.querySelector('#date');
    const time = form.querySelector('#time');
    const message = form.querySelector('.form-message');
    const submitButton = form.querySelector('button[type="submit"]');

    pool.addEventListener('change', () => {
        date.value = '';
        time.value = '';
        filterDates();
    });

    date.addEventListener('change', () => {
        time.value = '';
        filterTimes();
    });

    function filterDates() {
        date.querySelectorAll('option:not(:first-child)').forEach(opt => opt.remove());
        if (!pool.value) return;

        const template = document.querySelector('template[data-type="date"]');
        const options = template?.content?.querySelectorAll(`option[data-pool-id="${pool.value}"]`);
        options.forEach(opt => date.appendChild(opt.cloneNode(true)));
    }

    function filterTimes() {
        time.querySelectorAll('option:not(:first-child)').forEach(opt => opt.remove());
        if (!date.value) return;

        const template = document.querySelector('template[data-type="time"]');
        const options = template?.content?.querySelectorAll(
            `option[data-pool-id="${pool.value}"][data-date="${date.value}"]`
        );
        options.forEach(opt => time.appendChild(opt.cloneNode(true)));
    }

    form.addEventListener('submit', async (e) => {
        e.preventDefault();
        form.classList.add('opacity-50', 'pointer-events-none');

        const formData = new FormData(form);
        const submitData = new URLSearchParams(formData);

        submitData.append('stage', formData.get('pool'));
        submitData.append('nome', formData.get('firstName'));
        submitData.append('sobrenome', formData.get('lastName'));
        submitData.append('email', formData.get('email'));
        submitData.append('data', formData.get('date'));
        submitData.append('hora', formData.get('time'));
        submitData.append('obs', '');

        try {
            const response = await fetch(form.action, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: submitData.toString()
            });

            const data = await response.text();
            const jsonMatch = data.match(/({.*})/);
            if (jsonMatch) {
                const jsonData = JSON.parse(jsonMatch[1]);

                message.classList.remove('hidden');
                submitButton.classList.add('hidden');

                if (jsonData.type === 1) {
                    message.classList.remove('!text-red');
                    message.textContent = formData.get('successLabel');
                } else {
                    message.classList.add('!text-red');
                    message.textContent = formData.get('errorLabel');

                }

                form.reset();
            }
        } catch (error) {
            message.classList.remove('hidden');
            message.classList.add('!text-red');
            message.textContent = '{{ errorLabel }}';
        } finally {
            form.classList.remove('opacity-50', 'pointer-events-none');
        }
    });
};

const restartFormAndPools = () => {
    // Store all possible elements we might have attached listeners to
    const elements = [
        { id: '#bookingForm', events: ['submit'] },
        { id: '#pool', events: ['change', 'click'] },
        { id: '#date', events: ['change'] },
    ]

    // Remove listeners for each element if it exists
    elements.forEach(({ id, events }) => {
        const element = document.querySelector(id)
        if (element) {
            events.forEach(eventName => {
                // Remove all listeners for this event type
                element.replaceWith(element.cloneNode(true))
            })
        }
    })
}

const htmxContainer = document.querySelector('#htmx-container')
htmxContainer.classList.remove('is-showing')

const jsHtmxLinks = document.querySelectorAll('.js-htmx-link');

jsHtmxLinks.forEach((link) => {
    link.addEventListener('click', function (e) {
        htmxContainer.classList.add('is-hiding')
    })
});

htmxContainer.addEventListener('htmx:beforeSwap', function (evt) {
    const targetUrl = evt.detail.requestConfig.path || evt.detail.requestConfig.url
    document.querySelectorAll('[data-htmx-menu-item]').forEach((el) => {
        // pointer-events-none
        el.style.pointerEvents = 'none'
    });
    setTimeout(() => {
        htmxContainer.classList.add('is-showing')
        htmxContainer.classList.remove('is-hiding')
    }, 500)

    evt.detail.shouldSwap = false

    setTimeout(() => {
        // scroll to top
        window.scrollTo(0, 0)
    }, 500);

    setTimeout(() => {
        evt.detail.target.innerHTML = evt.detail.serverResponse

        if (targetUrl) {
            window.history.pushState({}, '', targetUrl)
        }

        // toggle sr-only on all [data-htmx-menu-item] elements
        document.querySelectorAll('[data-htmx-menu-item]').forEach((el) => {
            el.classList.toggle('sr-only')
            el.style.pointerEvents = 'auto'
        });


        // Force HTMX to scan the new content
        document.querySelector('script[data-htmx-script]')?.remove()
        htmx?.process(htmxContainer)

        Alpine.initTree(htmxContainer)


        htmxContainer.classList.remove('is-showing')

        restartFormAndPools()
        formAndPools()

    }, 1000)
})

// Initialize Alpine
window.Alpine = Alpine
Alpine.plugin(intersect)
Alpine.start()

formAndPools()